export default [
  {
    title: 'breadcrumb.news',
    iconType: 'line-awesome',
    icon: 'la-newspaper',
    children: [
      {
        title: 'breadcrumb.category',
        route: 'list-news-category',
        resource: 'news-category',
        action: 'read'
      },
      {
        title: 'breadcrumb.news',
        route: 'list-news',
        resource: 'news',
        action: 'read'
      },
    ],
  },
];