<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ $t("copyright.copyright") }} ©
      {{ moment().locale($i18n.locale).format("YYYY") }}
      <b-link class="ml-25" target="_blank">{{
        $t("copyright.vattanacBrewery")
      }}</b-link>
      <span class="d-none d-sm-inline-block">
        , {{ $t("copyright.allRightsReserved") }}
      </span>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BLink,
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
