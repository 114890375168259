export default [
  {
    title: 'breadcrumb.product',
    icon: 'la-beer',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.category',
        route: 'list-product-category',
        resource: 'product-category',
        action: 'read'
      },
      {
        title: 'breadcrumb.brand',
        route: 'list-product-brand',
        resource: 'product-brand',
        action: 'read'
      },
      {
        title: 'breadcrumb.product',
        route: 'list-product',
        resource: 'product',
        action: 'read'
      },
    ],
  },
];