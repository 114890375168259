export default [
  {
    title: 'breadcrumb.attendance',
    icon: 'la-clock',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.attendance',
        route: 'attendance',
        resource: 'attendance',
        action: 'read',
      },
      {
        title: 'breadcrumb.attendanceGroup',
        route: 'attendance-group',
        resource: 'attendance-group',
        action: 'read',
      },
      {
        title: 'breadcrumb.activity',
        route: 'list-activity',
        resource: 'activity',
        action: 'read',
      },
      {
        title: 'breadcrumb.travel',
        route: 'activity-travel',
        resource: 'activity-travel',
        action: 'read',
      },
    ],
  },
];