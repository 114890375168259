export default [
  {
    title: 'breadcrumb.leave',
    icon: 'la-calendar-times',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.leaveType',
        route: 'list-leave-type',
        resource: 'leave-type',
        action: 'read',
      },
      {
        title: 'breadcrumb.leaveBalance',
        route: 'list-leave-balance',
        resource: 'leave-balance',
        action: 'read',
      },
      {
        title: 'breadcrumb.leaveRequest',
        route: 'list-leave-request',
        resource: 'leave-request',
        action: 'read',
      },
      {
        title: 'breadcrumb.leaveRequestDetail',
        route: 'list-leave-request-detail',
        resource: 'leave-request-detail',
        action: 'read',
      },
    ],
  },
];