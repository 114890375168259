export default [
  {
    title: 'breadcrumb.outlet',
    iconType: 'line-awesome',
    icon: 'la-store-alt',
    children: [
      {
        title: 'breadcrumb.segment',
        route: 'list-outlet-segment',
        resource: 'outlet-segment',
        action: 'read'
      },
      {
        title: 'breadcrumb.outlet',
        route: 'list-outlet',
        resource: 'outlet',
        action: 'read'
      },
    ],
  },
];