export default [
  {
    title: 'breadcrumb.callPlan',
    iconType: 'line-awesome',
    icon: 'la-calendar-day',
    children: [
      {
        title: 'breadcrumb.callPlan',
        route: 'list-call-plan',
        resource: 'call-plan',
        action: 'read'
      },
      {
        title: 'breadcrumb.countStock',
        route: 'list-count-stock',
        resource: 'count-stock',
        action: 'read'
      },
      {
        title: 'breadcrumb.massBranding',
        route: 'list-mass-branding',
        resource: 'mass-branding',
        action: 'read'
      },
      {
        title: 'breadcrumb.marketSurvey',
        route: 'list-market-survey',
        resource: 'market-survey',
        action: 'read'
      },
      {
        title: 'breadcrumb.order',
        route: 'list-call-plan-order',
        resource: 'call-plan-order',
        action: 'read'
      },
      {
        title: 'breadcrumb.productDisplay',
        route: 'list-product-display',
        resource: 'product-display',
        action: 'read'
      },
    ],
  },
];