export default [
  {
    title: 'breadcrumb.report',
    icon: 'la-book',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.orderSummary',
        route: 'order-summary-report',
        resource: 'order-summary-report',
        action: 'read'
      },
      {
        title: 'breadcrumb.outletRegistration',
        route: 'outlet-registration-report',
        resource: 'outlet-registration-report',
        action: 'read'
      },
      {
        title: 'breadcrumb.activeOutlet',
        route: 'active-outlet-report',
        resource: 'active-outlet-report',
        action: 'read'
      },
    ],
  },
];