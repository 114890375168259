<template>
  <div class="text-center">
    <b-img
      class="w-100 no-record-img"
      :src="require('@/assets/images/icons/no-data.svg')"
      fluid
    />
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue";

export default {
  components: {
    BImg,
  },
};
</script>