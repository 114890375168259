export default [
  {
    title: 'breadcrumb.salesperson',
    icon: 'la-user-tie',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.salesperson',
        route: 'list-salesperson',
        icon: '',
        resource: 'salesperson',
        action: 'read'
      },
      {
        title: 'breadcrumb.department',
        route: 'list-department',
        icon: '',
        resource: 'department',
        action: 'read'
      },
      {
        title: 'breadcrumb.position',
        route: 'list-position',
        icon: '',
        resource: 'position',
        action: 'read'
      },
      {
        title: 'breadcrumb.workingSchedule',
        route: 'list-working-schedule',
        icon: '',
        resource: 'working-schedule',
        action: 'read'
      },
    ],
  },
];